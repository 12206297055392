import { ArpitaRepoImpl } from "domain/repository/Arpita/ArpitaRepoImpl";
import { NavigationRepositoryImpl } from "domain/repository/Navigation/NavigationRepositoryImpl";
import recursionPropertyInsertion from "domain/utils/recursionPropertyInsertion";
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
// import { ArpitaOverflowMenu } from "presentation/view/components/workspace/ArpitaOverflowMenu/ArpitaOverflowMenu";
// import { convertTemplateId } from "presentation/view/components/workspace/Modal/Modal";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent, customEventListener } from "helpers/Events";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { useEnvironmentVariableConfig } from "presentation/hook/useEnvironmentVariableConfig";
import { isEnvironmentForTesting } from "presentation/utils/EnvironmentVariable/EnvironmentVariableUtil";
import { retrieveUniqueMFENavigation } from "presentation/utils/menuUtil";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { OverflowingMenu } from "presentation/view/components/workspace/OverflowingMenu/OverflowingMenu";
import NavigationMenuVM from "presentation/viewModel/workspace/NavigationMenuVM/NavigationMenuVM";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Core } from "veronica-ui-component";
import MiniToolbar from "../MiniToolbar/MiniToolbar";

const { Container, IconButton, RightClickMenu } = Core;
const Nav: any = Core.NavigationMenu;

interface NavigationMenuProps {
    [x: string]: any;
}

const NavigationMenu: React.FC<NavigationMenuProps> = (): any => {
    const [workspaceState, setWorkspaceState] = useWorkspaceTracked();
    const { userName, currentOperatingCompany, allNbisivPermission } = useANAInfo();

    const { navigation, subNavigation, chargeSearch, documentSearch, documentItemSearch, proposalSearch, } = new NavigationRepositoryImpl().getNavigation(workspaceState.mainMenu); //arpitaTemplate
    const [eventData, setEventData] = useState<{ [key: string]: any }>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showMore, setshowMore] = useState(false);
    const [moreContent, setMoreContent] = useState(true);
    const {
        showChargeSearch,
        showDocumentSearch,
        showDocumentItemSearch,
        showProposalSearch,
        // arpitaTemplates,
        // openArpitaTemplates,
        navigationBarPinned,
    } = workspaceState;
    const [showNav, setShowNav] = useState(false);
    const menuRef: any = React.createRef();

    const isShowChargeSearch = isPermissionExist(Permission.CHARGE_DATA_ENQUIRY, AclType.READ, currentOperatingCompany, allNbisivPermission);
    const isShowDocumentSearch = isPermissionExist(Permission.DOCUMENT_ENQUIRY, AclType.READ, currentOperatingCompany, allNbisivPermission);
    const isShowProposalSearch = isPermissionExist(Permission.PROPOSAL_ENQUIRY, AclType.READ, currentOperatingCompany, allNbisivPermission);

    const chargeSearchHandlerForRedirect = useCallback((e: any) => {
        switch (e.detail.action) {
            case E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY:
                setWorkspaceState(prevState => ({
                    ...prevState,
                    showChargeSearch: true
                }))
                if (e) {
                    const showChargeSearch = new CustomEvent('showChargeSearch', {});
                    document.dispatchEvent(showChargeSearch);
                }

                setEventData({ [E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY]: e.detail.data.data });
                break;
        }


    }, [setWorkspaceState]);

    useEffect(() => {
        customEventListener(E_Type_Of_Event.DELIVERY_SUMMARY_REDIRECT_EVENT, chargeSearchHandlerForRedirect);
    }, [chargeSearchHandlerForRedirect]);



    useEffect(() => {
        if (eventData) {
            const timerId = setTimeout(() => {
                if (eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY]) {
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL,
                        E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY,
                        { data: eventData[E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY] });
                }
            }, 500);

            return () => { clearTimeout(timerId) }
        }
    }, [eventData]);


    const navigationMenuVM = useMemo(() =>
        NavigationMenuVM({
            dispatch: [setWorkspaceState],
            arpitaRepo: ArpitaRepoImpl()
        }), [setWorkspaceState]
    )

    const command = useMemo(() => (e: any): void => {
        const secondaryMenu: any = document.querySelector('.secondary-menu')?.querySelector('.sub-menu');
        const secondaryItems = secondaryMenu?.querySelectorAll('.icon-wrapper');
        if (!e.submenu && secondaryMenu) {
            e.isMainMenu = true;
            navigationMenuVM.onOpenMFE(e, userName);
            secondaryMenu.innerHTML = " ";
            setMoreContent(true);
            setTimeout(() => {
                const menuIcon = document?.querySelector('.menu-icons');
                secondaryMenu?.insertAdjacentHTML('beforeend', `${menuIcon?.innerHTML}`);
                secondaryItems?.classList?.add('hidden');
                const showYardEditor = new CustomEvent('yardEditor', { detail: { yardEditor: true } })
                const hideYardEditor = new CustomEvent('yardEditor', { detail: { yardEditor: false } })
                const closeDialogs = new CustomEvent('closeDialog')
                if (e.title === 'Editor') {
                    document.dispatchEvent(showYardEditor);
                } else {
                    document.dispatchEvent(hideYardEditor);
                }
                if (e.title !== "Operation Monitoring") {
                    document.dispatchEvent(closeDialogs);
                }
            }, 200);
            localStorage.getItem("oliviaId") && localStorage.removeItem('oliviaId')
        }
    }, [navigationMenuVM, userName])

    const subModuleCommand = (e: any): void => {
        if (!e.submenu) {
            e.isMainMenu = false;
            navigationMenuVM.onOpenMFE(e.item, userName);
            localStorage.getItem("oliviaId") && localStorage.removeItem('oliviaId')
        }
    };

    const modifiedNav = recursionPropertyInsertion(navigation, { command }).filter((nav) => nav.isVisiable !== false);
    const subModuleNav = recursionPropertyInsertion(subNavigation, { command: subModuleCommand });

    const secondaryMenu = document?.querySelector('.secondary-menu')?.querySelector('.sub-menu')
    const navigationItem = secondaryMenu?.querySelector('.navigation-item');
    const overflowWrapperIcon: any = navigationItem?.querySelector('.overflow-wrapper');


    navigationItem?.addEventListener('click', (e) => {
        overflowWrapperIcon?.classList.toggle('show-list')
    });

    const chargeSearchHandler = (e: any) => {
        setWorkspaceState(prevState => ({
            ...prevState,
            showChargeSearch: !showChargeSearch
        }))
        if (e) {
            const showChargeSearch = new CustomEvent('showChargeSearch', {});
            document.dispatchEvent(showChargeSearch);
        }
    };

    const documentSearchHandler = (e: any) => {
        setWorkspaceState(prevState => ({
            ...prevState,
            showDocumentSearch: !showDocumentSearch
        }))
        if (e) {
            const showDocumentSearch = new CustomEvent('showDocumentSearch', {});
            document.dispatchEvent(showDocumentSearch);
        }
    }

    const proposalSearchHandler = (e: any) => {
        setWorkspaceState(prevState => ({
            ...prevState,
            showProposalSearch: !showProposalSearch
        }))
        if (e) {
            const showProposalSearch = new CustomEvent('showProposalSearch', {});
            document.dispatchEvent(showProposalSearch);
        }
    }

    const documentItemSearchHandler = (e: any) => {
        setWorkspaceState(prevState => ({
            ...prevState,
            showDocumentItemSearch: !showDocumentItemSearch
        }))
        if (e) {
            const showDocumentItemSearch = new CustomEvent('showDocumentItemSearch', {});
            document.dispatchEvent(showDocumentItemSearch);
        }
    }

    // const arpitaTemplateHandler = (e: React.SyntheticEvent | null, actionType: E_ArpitaHandlerAction, templateId: string, template?: ArpitaTemplateEntity) => {
    //     navigationMenuVM.arpitaDailogHandler(token, e, actionType, templateId, template);

    //     if (e) {
    //         const showArpitaMenu = new CustomEvent('showArpitaMenu', {});
    //         document.dispatchEvent(showArpitaMenu);
    //     }
    // }

    useEffect(() => {
        return () => {
            const navMenu: any = document.querySelector('.navigation-menu')
            const menuIcon = document?.querySelector('.menu-icons')
            const secondaryMenu = document?.querySelector('.secondary-menu')?.querySelector('.sub-menu')
            const primaryMenuIcon: any = navMenu?.querySelectorAll('.menu-icons > .icon-wrapper:not(.more)')

            const secondaryItems = secondaryMenu?.querySelectorAll('.icon-wrapper')
            const allItems = navMenu?.querySelectorAll('.icon-wrapper')
            const more = menuIcon?.querySelector('.more')
            const libraryPreIcon = menuIcon?.querySelector('.general-menu-icon')
            const libraryIcon = secondaryMenu?.querySelector('.general-menu-icon')


            const moreBtn = more?.querySelector('.more-icon')
            const doAdapt = () => {
                allItems?.forEach((item: any) => {
                    item.classList.remove('hidden')
                    // setshowMore(true)
                })

                let stopWidth: any = moreBtn?.clientHeight
                let hiddenItems: any = []
                const primaryWidth = window.innerHeight - 165
                primaryMenuIcon?.forEach((item: any, i: any) => {
                    if (primaryWidth >= stopWidth + item?.offsetHeight) {
                        stopWidth += item?.offsetHeight;
                        more?.classList.add('hidden')
                        libraryIcon?.classList.add('hidden')
                        libraryPreIcon?.classList.remove('position')

                    } else {
                        item.classList.add('hidden');
                        more?.classList.remove('hidden');
                        libraryPreIcon?.classList.add('position')
                        hiddenItems.push(i)
                    }
                })

                if (!hiddenItems.length) {
                    navMenu?.classList.remove('show-secondary')
                } else {
                    secondaryItems?.forEach((item, i) => {
                        if (!hiddenItems.includes(i)) {
                            item.classList.add('hidden')
                        }
                    })
                }
            }
            setTimeout(() => {
                doAdapt();
            }, 300);

            window.addEventListener('resize', doAdapt)
            document.addEventListener('click', (e) => {
                let el: any = e.target
                while (el) {
                    if (el === secondaryMenu || el === moreBtn) {
                        return;
                    }
                    el = el.parentNode
                }
                navMenu?.classList.remove('show-secondary')
                // setshowMore(false)
            })
        }
    }, [command, workspaceState.showNavigation])

    const allMfeNavigation = retrieveUniqueMFENavigation(navigation ?? []);
    const currentNavigation = allMfeNavigation.find(mef => mef.entrypoint === workspaceState.entrypoint);
    const currentMEF = currentNavigation ? (currentNavigation.customTitle ?? currentNavigation.title) : workspaceState.mfeTitle;

    const speechCommands = [
        {
            command: allMfeNavigation.map(navigation => `open ${navigation.title}`),
            callback: (command: string, spokenPhrase: string, similarityRatio: string) => {

                navigationMenuVM.onOpenMFE(allMfeNavigation.find((navigation) => navigation.title?.toUpperCase() === command.toUpperCase().slice(5)), userName)
            },
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.4,
            bestMatchOnly: true,
        },
        {
            command: 'owner',
            callback: (arg1: any, arg2: any, arg3: any) => {



            }
        },
        {
            command: 'owner is',
            callback: (arg1: any, arg2: any, arg3: any) => {



            }
        },
        {
            command: 'owner is *',
            callback: (arg1: any, arg2: any, arg3: any) => {



            }
        },
    ];

    const clickMenuItems = {
        forPin: [
            {
                label: "Pin",
                command: () =>
                    setWorkspaceState((prevState) => ({
                        ...prevState,
                        navigationBarPinned: true,
                    })),
            },
        ],
        forUnpin: [
            {
                label: "Unpin",
                command: () =>
                    setWorkspaceState((prevState) => ({
                        ...prevState,
                        navigationBarPinned: true,
                    })),
            },
        ],
    };

    const { listening } = useSpeechRecognition({ commands: speechCommands });

    useEnvironmentVariableConfig();

    // const getTemplates = useMemo(() => arpitaTemplates?.map((template: ArpitaTemplateEntity) => {
    //     const tId: string = convertTemplateId(template.id);

    //     return {
    //         ...template,
    //         isOpen: openArpitaTemplates && openArpitaTemplates[tId] ? true : false,
    //     };
    // }), [arpitaTemplates, openArpitaTemplates]);

    //add listener to open the Modal main view

    return (<><div className={`navigation-menu ${workspaceState.expanded ? "expanded" : ""} ${workspaceState.mfeFullscreen ? (!showNav ? (!navigationBarPinned ? "overlay-active" : '') : '') : ""}`}>
        <Container borderRadius="roundAll" height="100%" theme="theme3" width="100%" style={{ overflow: "visible" }}>

            {/* Brand Icon */}
            <div className="brand-icon-wrapper">
                <span className="brand-icon"></span>
            </div>

            {/* Horizontal seprator between hamburger and Brand Icon */}
            <div className="horizontal-seprator">
                <span></span>
            </div>

            {/* Hamburger Menu List */}
            <Nav menuItems={modifiedNav} onSubMenuExpend={true} onSubMenuLeave={(e: boolean) => setShowNav(e)} />

            {/* Horizontal seprator between hamburger and sub navigation */}
            {workspaceState.showNavigation && workspaceState.mfeTitle !== "" && <div className="horizontal-seprator">
                <span></span>
            </div>}

            {/* Sub navigation based on menu selection */}
            <div id="ws-menu-icons" className="menu-icons">
                {
                    workspaceState.showNavigation && currentMEF !== "" &&
                    subModuleNav?.filter((item) => item.menuName === currentMEF)
                        ?.map((item: any, i: number) => {
                            return item.separator ? (
                                i !== subModuleNav?.filter((item) => item.menuName === currentMEF)?.length - 1 ?
                                    <div key={i} className="horizontal-seprator">
                                        <span></span>
                                    </div> :
                                    <></>
                            ) : (
                                <div key={i} className={`icon-wrapper icon-wrapper-${item.entrypoint}`}>
                                    {item.navigationList ? (
                                        <OverflowingMenu menuItem={item} />
                                    ) : (
                                        <IconButton
                                            fileName={item.icon ?? 'Icon-doc'}
                                            toolTipArrow={false}
                                            toolTipPlacement="right"
                                            toolTipText={item.title}
                                            enabled={item.title === currentMEF}
                                            onClick={() => {
                                                item.entrypoint &&
                                                    navigationMenuVM.onOpenMFE(item, userName);
                                                localStorage.getItem("oliviaId") && localStorage.removeItem('oliviaId');
                                                localStorage.getItem("ChargeHeaderSearchCriteria") && localStorage.removeItem("ChargeHeaderSearchCriteria");
                                                localStorage.getItem("ChangeKeyDataSearchCriteria") && localStorage.removeItem("ChangeKeyDataSearchCriteria");
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        })
                }

                <div id="ws-menu-icons-portal"></div>

                <div className="more">
                    <button onClick={() => { setMoreContent(!moreContent) }} className="more-icon" aria-haspopup="true" aria-expanded="true">
                        {showMore && <IconButton fileName="Icon-more" toolTipArrow={false} toolTipPlacement="right" toolTipText="More" tooltipDisable={!moreContent} />}
                    </button>
                    <div onMouseLeave={() => { setMoreContent(true); overflowWrapperIcon?.classList.remove('show-list') }} id="secondary-menu" style={{ display: `${moreContent ? "none" : "inline-flex"}` }} className="secondary-menu">
                        <div className="sub-menu"></div>
                    </div>
                </div>

                {/* Show common icon buttons */}
                <div className="general-menu-icon">
                    {workspaceState.showNavigation && subModuleNav?.filter((item) => item.menuName === workspaceState.mfeTitle).length !== 0 && <div className="horizontal-seprator"><span></span></div>}

                    {chargeSearch && chargeSearch[0] && isShowChargeSearch && <IconButton fileName="Icon-cntr-search" toolTipArrow={false} toolTipText="Charge Search" toolTipPlacement="right" onClick={(e: any) => chargeSearchHandler(e)} />}

                    {documentSearch && documentSearch[0] && isShowDocumentSearch && <IconButton fileName="Icon-invoice-search" toolTipArrow={false} toolTipText="Invoice/Credit Note Search" toolTipPlacement="right" onClick={(e: any) => documentSearchHandler(e)} />}

                    {documentItemSearch && documentItemSearch[0] && <IconButton fileName="Icon-movement-search" toolTipArrow={false} toolTipText="Document Item Search" toolTipPlacement="right" onClick={(e: any) => documentItemSearchHandler(e)} />}

                    {proposalSearch && proposalSearch[0] && isShowProposalSearch && <IconButton fileName="Icon-propossal-search" toolTipArrow={false} toolTipText="Proposal Search" toolTipPlacement="right" onClick={(e: any) => proposalSearchHandler(e)} />}

                    {/* {arpitaTemplate && arpitaTemplate[0] && <div key={'arpita-template-icon'} className="icon-wrapper"><ArpitaOverflowMenu menuItem={getTemplates} onClick={arpitaTemplateHandler} /></div>}                     */}
                </div>

                {/* Start recording and stop recording Icon button */}
                {
                    isEnvironmentForTesting(workspaceState.environmentVariable) &&
                    <div className="general-menu-icon">
                        {listening && <IconButton fileName="Icon-book" toolTipArrow={false} toolTipText="Stop Recording (Testing)" toolTipPlacement="right" onClick={SpeechRecognition.stopListening} />}
                        {!listening && <IconButton fileName="Icon-book" toolTipArrow={false} toolTipText="Start Recording (Testing)" toolTipPlacement="right" onClick={() => SpeechRecognition.startListening()} />}
                    </div>
                }
            </div>
            <div style={{ height: "100%" }} onContextMenu={(e) => menuRef.current.show(e)}></div>

            {/* User profile and setting menu */}
            <MiniToolbar />
        </Container>
    </div>
        <RightClickMenu className="pin-toolbar-menu" element={menuRef} items={navigationBarPinned ? clickMenuItems.forUnpin : clickMenuItems.forPin} onMouseLeave={(e) => menuRef.current.hide(e)} />
    </>);
};

export { NavigationMenu };

